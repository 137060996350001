import { getProcessorsByUser, deleteAnnualEnvironment, postAnnualEnvironment, getAnnualEnvironment2, putAnnualEnvironment } from '@/api/dataentry-api-generated';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SaveDialog from '@/components/SaveDialog.vue';
import { defineComponent, ref } from '@vue/runtime-core';
export default defineComponent({
    name: 'AnnualEnvironmentComponent',
    components: { DeleteDialog, SaveDialog },
    props: {
        propSelected: Array,
        propCreateNew: Boolean,
        propDuplicateRecord: Boolean,
        propId: String
    },
    data: function () {
        return {
            filter: '',
            effectiveDates: '',
            createNew: this.propCreateNew,
            duplicateRecord: this.propDuplicateRecord,
            id: this.propId,
            loading: false,
            processors: [],
            item: {},
            processorOptions: [{
                    label: '',
                    value: ''
                }],
            processorDisplay: {
                label: '',
                value: ''
            },
            tablePagination: {
                sortBy: 'site',
                descending: true,
                rowsPerPage: 50
            },
            signedAPCOValue: ref(),
            dropdownOptions: ['Yes', 'No']
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        selectedItems() {
            return this.propSelected;
        },
        buttonLabel() {
            return 'SAVE';
        },
        years() {
            // Generate values from the past 20 years until the current year
            const currentYear = new Date().getFullYear();
            return Array.from({ length: 21 }, (_, i) => `${currentYear - i} (Jul 1 ${(currentYear - i) - 1} - Jun 30 ${currentYear - i})`);
        }
    },
    created() {
        this.getProcessors();
        if (!this.createNew) {
            this.getItem(this.uuid, true);
        }
        if (this.duplicateRecord) {
            if (this.id !== undefined) {
                this.getItem(this.id, false);
            }
        }
    },
    methods: {
        async getItem(uuid, isUpdate) {
            this.loading = true;
            try {
                const result = await getAnnualEnvironment2(uuid);
                this.item = result;
                if (isUpdate) {
                    this.item.yearEntry = result.yearEntry;
                    this.processorDisplay.label = result.processorLabel;
                    this.processorDisplay.value = result.processor;
                    this.signedAPCOValue = result.signedAPCO === null ? undefined : result.signedAPCO === true ? 'Yes' : 'No';
                }
                else {
                    this.item.yearEntry = new Date().getFullYear();
                    this.signedAPCOValue = result.signedAPCO === null ? undefined : result.signedAPCO === true ? 'Yes' : 'No';
                }
                this.loading = false;
            }
            catch (error) {
                const errMsg = error;
                if (errMsg.message.includes('ResourceNotFoundException')) {
                    this.closeDialog();
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        saveItem() {
            if (!this.createNew) {
                this.updateItem();
            }
            else {
                this.createItem();
            }
        },
        async updateItem() {
            // Validate the form before proceeding
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                req.yearEntry = this.item.yearEntry;
                req.signedAPCO = this.signedAPCOValue === 'Yes' ? true : this.signedAPCOValue === 'No' ? false : null;
                try {
                    const req = this.item;
                    req.processor = this.processorDisplay.value;
                    await putAnnualEnvironment(this.uuid, this.item);
                    this.$log.addMessage('Successfully Updated Annual Environment');
                    this.$router.push({ name: 'Annual Environments' });
                }
                catch (error) {
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        async createItem() {
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                req.yearEntry = this.item.yearEntry;
                req.processor = this.processorDisplay.value;
                req.signedAPCO = this.signedAPCOValue === 'Yes' ? true : this.signedAPCOValue === 'No' ? false : null;
                try {
                    await postAnnualEnvironment(req);
                    this.$log.addMessage('Successfully created new Annual Environment');
                    this.$emit('refresh');
                    this.closeDialog();
                }
                catch (error) {
                    const errorObj = error;
                    if (errorObj.message.includes('ResourceAlreadyExistsException')) {
                        const uuid = errorObj.message.substring(31, errorObj.message.length);
                        const msg = `A record with the same Annual Environment already exists: <a href="#/AnnualEnvironment/${uuid}">VIEW</a>`;
                        const errorMsg = {
                            type: 'warning',
                            textColor: 'black',
                            timeout: 5000,
                            position: 'top',
                            message: msg,
                            html: true
                        };
                        this.$log.addCustomMessage(errorMsg);
                    }
                    else {
                        this.$log.addError(error);
                    }
                    this.loading = false;
                }
            }
        },
        async deleteItem() {
            try {
                const result = await deleteAnnualEnvironment(this.uuid);
                this.item = result;
                this.$log.addMessage('Successfully deleted Annual Environment');
                this.closeDialog();
            }
            catch (error) {
                this.$log.addError(error);
            }
        },
        closeDialog() {
            if (this.createNew) {
                this.$emit('show-hide-dialog');
            }
            else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push({ name: 'Annual Environments' });
        },
        async getProcessors() {
            this.loading = true;
            try {
                const result = await getProcessorsByUser({ pageSize: 1000000, deleted: false });
                this.processors = result.items;
                this.processorOptions = this.getProcessorOptions();
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        getProcessorOptions() {
            return this.processors.map(i => {
                return {
                    label: i.processorLabel.slice(0, -6),
                    value: i.uuid
                };
            });
        },
        filterOptions(val, update) {
            if (val === '') {
                update(() => {
                    // No filter - display default list
                    this.processorOptions = this.getProcessorOptions();
                });
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.processorOptions = this.getProcessorOptions().filter(v => v.label.toLowerCase().indexOf(needle) > -1);
            });
        }
    }
});
